import React, { useEffect, useRef, useState } from "react";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  Box, Grid, IconButton, ToggleButton,
  ToggleButtonGroup, Typography,
} from "@mui/material";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import FormBox from "../../styled/generic/FormBox";
import { Add, TaskAltOutlined } from "@mui/icons-material";
import Button from "../../styled/generic/Button";
import TextField from "../../styled/generic/TextField";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from "xlsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";
import TargetTasksList from "../tasks/TargetTasksList";
import { getTargetDetailsById } from "../api.call";
import { Breadcrumbs, Link } from '@mui/material';
import configObject from '../../../config/index'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white"
  },
  emptySty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflow: "hidden",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  loaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  breadcrumbs: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
}));

const PlanningTarget = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const filePickerRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { targetId } = useParams();
  const history = useHistory();

  const [target, setTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [taskView, setTaskView] = useState("list");
  const [fileData, setFileData] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [reload, setReload] = useState(false);
  const [userProfileId, setUserProfileId] = useState(null);
  const [orgProfileId, setOrgProfileId] = useState(null);
  const [projectProfileId, setProjectProfileId] = useState(null);
  const [selectedTables, setSelectedTables] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});

  useEffect(() => {
    setUserProfileId(user?.profile);
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Resource Access
    </Button>
  )

  const {
    sharedProfilesRole, ShareButton, ShareDrawer, SharedView,
    assignedProfiles, AssignButton, AssigneDrawer, AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole, initAssigned: null, resourceId: targetId,
    resourceName: 'Target', customShareBtn: shareBtn, customAssignBtn: assignBtn,
  });

  const getTarget = async () => {
    try {
      setLoading(true);
      await getTargetDetailsById({ targetId })
        .then((data) => {
          if (data) {
            setTarget(data);
            setOrgProfileId(data?.organization?.profile)
            setProjectProfileId(data?.project?.profile)
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching target details",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching target details",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTarget();
  }, []);

  //import related functionalities---------------------------------------------------------------start
  const handleFileSelection = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    let file = fileData;
    if (file && file.arrayBuffer) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { defVal: "" });
      setSheetNames(workbook.SheetNames);
      setSheets(workbook.Sheets);
    } else {
      console.log("No uploaded file found");
    }
  };

  useEffect(() => {
    if (fileData) {
      handleFileUpload();
    }
  }, [fileData]);

  const addTable = () => {
    let tableObj = {
      title: "",
      description: "",
      progress: "",
      isValid: false,
      data: {
        titles: [],
        descriptions: [],
        progresses: [],
      },
    };
    setSelectedTables([...selectedTables, tableObj]);
  };

  useEffect(() => {
    addTable();
  }, []);

  function isValidCellString(str) {
    const pattern = /^\d+[A-Z]$/;
    return pattern.test(str);
  }

  const handleTableValidation = (index, key, _value) => {
    let value = String(_value).toUpperCase();
    if (key === "title") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].title = value;
        //newTables[index].data.titles = [];
        return newTables;
      });
    } else if (key === "description") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].description = value;
        //newTables[index].data.descriptions = [];
        return newTables;
      });
    } else if (key === "progress") {
      setSelectedTables((prev) => {
        let newTables = [...prev];
        newTables[index].progress = value;
        //newTables[index].data.progresses = [];
        return newTables;
      });
    }

    let newTables = [...selectedTables];
    let table = newTables[index];
    let isValid = false;
    let sheet = sheets[selectedSheetName];
    console.log("Sheet is: ", sheet);

    // Check if value is valid i.e in format of 100A:200A
    let split = value.split(":");
    if (split.length !== 2) {
      isValid = false;
      console.log("Invalid split");
      return;
    } else {
      let start = split[0]; // 110A
      let end = split[1]; // 200A

      // Check if start and end are valid
      if (!isValidCellString(start)) {
        console.log("Start is invalid");
        isValid = false;
        return;
      }

      if (!isValidCellString(end)) {
        console.log("End is invalid");
        isValid = false;
        return;
      }

      // Now we are sure that start and end are valid eg. 110A and 200A

      let startLetter = start.slice(-1);
      let endLetter = end.slice(-1);
      console.log("Start letter: ", startLetter);
      console.log("End letter: ", endLetter);

      if (startLetter !== endLetter) {
        isValid = false;
        console.log("Col letters dont match");
      } else {
        let startNumber = start.slice(0, -1);
        let endNumber = end.slice(0, -1);
        console.log("start: ", start);
        console.log("end: ", end);
        console.log("Start Number: ", startNumber);
        console.log("End Number: ", endNumber);

        if (isNaN(startNumber) || isNaN(endNumber)) {
          console.log("Start or end Number is invalid");
          isValid = false;
        }

        startNumber = parseFloat(startNumber);
        endNumber = parseFloat(endNumber);

        if (startNumber > endNumber) {
          isValid = false;
          console.log("Start num cant be more than end num");
        } else {
          // Form the range
          let range = [];
          console.log("Start num: ", startNumber);
          console.log("End num: ", endNumber);

          for (let i = startNumber; i <= endNumber; i++) {
            range.push(startLetter + i);
          }
          console.log("Range is: ", range);

          console.log("Object entries: ", Object.entries(sheet));

          // Insert data

          for (let i = 0; i < range.length; i++) {
            //console.log("RANGE LOOP: ", i);
            let cell = range[i];

            let data = sheet[cell];
            console.log("CELL: ", cell);
            console.log("DATA: ", data);
            if (!data) {
              if (key === "title") {
                table.data.titles.push("");
              } else if (key === "description") {
                table.data.descriptions.push("");
              } else if (key === "progress") {
                table.data.progresses.push("");
              } else {
                console.log("Invalid key");
              }
              isValid = true;
            } else {
              let sheetVal = data?.v || data?.h || data?.w || "";
              if (key === "title") {
                table.data.titles.push(sheetVal);
              } else if (key === "description") {
                table.data.descriptions.push(sheetVal);
              } else if (key === "progress") {
                table.data.progresses.push(sheetVal);
              } else {
                console.log("Invalid key");
              }
              isValid = true;
            }
          }
        }

        // Update table state
        table.isValid = isValid;
        newTables[index] = table;
        setSelectedTables(newTables);
        console.log("New tables: ", newTables);
      }
    }
  };

  const handlePreupload = () => {
    let max = 0;
    let tables = [...selectedTables];
    for (let i = 0; i < tables?.length; i++) {
      let table = tables[i];
      let data = table?.data;
      let titles = data?.titles;
      let descriptions = data?.descriptions;
      let progresses = data?.progresses;
      max = titles?.length;
      if (descriptions?.length > max) {
        max = descriptions?.length;
      } else if (progresses?.length > max) {
        max = progresses?.length;
      }
      // Make all arrays equal in length
      for (let j = titles?.length; j <= max; j++) {
        titles.push("");
      }

      for (let j = descriptions?.length; j <= max; j++) {
        descriptions.push("");
      }

      for (let j = progresses?.length; j <= max; j++) {
        progresses.push("");
      }
    }
    console.log("FINAL TABLES TO UPLOAD: ");
    console.log(tables);

    // Merge them
    let tablesObjects = [];
    for (let i = 0; i < tables?.length; i++) {
      let table = tables[i];
      let data = table?.data;
      for (let j = 0; j < max; j++) {
        let parsedPerc = String(data.progresses[j]).replace("%", "");
        let obj = {
          title: data.titles[j],
          description: data.descriptions[j],
          progress: parsedPerc,
        };
        tablesObjects.push(obj);
      }
    }

    return tablesObjects;
  };

  const uploadTasks = async () => {
    let tasks = handlePreupload();
    console.log("Table objects: ", tasks);
    try {
      const { data } = await Api.post("/wbs/upload", {
        targetId: targetId,
        tasks: tasks,
        creatorProfileId: user?.profile,
        shared:
          sharedProfilesRole && sharedProfilesRole.length > 0
            ? sharedProfilesRole.map((s) => s?._id)
            : [],
        assigned:
          assignedProfiles && assignedProfiles.length > 0
            ? assignedProfiles.map((s) => s?._id)
            : [],
        accessRoles: sharedProfilesRole,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Tasks uploaded successfully",
          },
        });
        setShowUploadDialog(false);
        setReload(!reload);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while uploading tasks",
        },
      });
    }
  };
  //import related functionalities---------------------------------------------------------------end

  return (
    <div className={classes.root}>
      {loading ? (<div className={classes.loaderCont} >
        <CircularProgress />
      </div>) : (<>
        {target?._id ? (<>
          <div className={classes.topBar}>

            <div className={classes.barLeft}>
                <IconButton
                  className={classes.iconButtonStyle}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <KeyboardBackspaceIcon className={classes.backArrow} />
                </IconButton>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator=">"
                  className={classes.breadcrumbs}
                >
                  {target?.organization ? (
                    <Link
                      color="inherit"
                      onClick={() => {
                        const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/organization/${target.organization.team}`;
                        configObject.mode === 'prod' 
                          ? window.open(url, "_self")
                          : history.push(url);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {target.organization.name || 'Organization'}
                    </Link>
                  ) : null}
                  {target?.project ? (
                    <Link
                      color="inherit"
                      onClick={() => {
                        const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/project/${target.project.team}`;
                        configObject.mode === 'prod'
                          ? window.open(url, "_self")
                          : history.push(url);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {target.project.name || 'Project'}
                    </Link>
                  ) : null}
                  <Typography color="textPrimary">
                    {target?.title || "Untitled"}
                  </Typography>
                </Breadcrumbs>
            </div>

            <div className={classes.barRight}>
              {ShareButton}
              {ShareDrawer}
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => setShowUploadDialog(true)}
              >
                Upload
              </Button>
              <ToggleButtonGroup
                value={taskView}
                exclusive
                onChange={(evt, val) => setTaskView(val)}
                size="small"
                sx={{ marginLeft: "10px" }}
              >
                <ToggleButton value="list">
                  <TaskAltOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className={classes.editBody}>
            <TargetTasksList target={target} reload={reload} />
            <DrawerContainer
              open={showUploadDialog}
              setOpen={setShowUploadDialog}
              title="Upload Tasks From Excel"
            >
              <Box>
                <Box>
                  {fileData ? (
                    <Box>
                      <FormBox label="Select Sheet">
                        <RadioGroup
                          row
                          value={selectedSheetName}
                          onChange={(e) => setSelectedSheetName(e.target.value)}
                        >
                          {sheetNames.map((sheetName) => (
                            <FormControlLabel
                              value={sheetName}
                              control={<Radio />}
                              label={sheetName}
                            />
                          ))}
                        </RadioGroup>
                      </FormBox>
                      <FormHeaderWithActions
                        label="Tables"
                        actions={
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => addTable()}
                          >
                            Add New Table
                          </Button>
                        }
                      />
                      {!selectedSheetName ? (
                        <Box>
                          <Typography variant="body1">
                            Please select a sheet
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          {selectedTables.map((table, index) => {
                            return (
                              <Box sx={{ mt: 2 }}>
                                {!table?.isValid && (
                                  <Box sx={{ my: 1 }}>
                                    Please properly fill all the fields, 10A:20A or
                                    30E:90E for example
                                  </Box>
                                )}
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <TextField
                                      label="Title"
                                      value={table.title}
                                      onChange={(e) =>
                                        handleTableValidation(
                                          index,
                                          "title",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      label="Description"
                                      value={table.description}
                                      onChange={(e) =>
                                        handleTableValidation(
                                          index,
                                          "description",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      label="Progress"
                                      value={table.progress}
                                      onChange={(e) =>
                                        handleTableValidation(
                                          index,
                                          "progress",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      <FormBox label="Assignees">
                        {AssignButton} {AssigneDrawer}
                        <div>{AssignView}</div>
                      </FormBox>
                      <DuoButtonGroup
                        primaryButtonText="Upload"
                        primaryButtonListener={() => uploadTasks()}
                        secondaryButtonText="Cancel"
                        secondaryButtonListener={() => {

                        }}
                        disablePrimaryButton={
                          selectedTables.length === 0 || !selectedSheetName
                        }
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => filePickerRef.current.click()}
                      >
                        Click here to select file
                      </Button>
                      <input
                        type="file"
                        multiple={false}
                        hidden
                        ref={filePickerRef}
                        onChange={handleFileSelection}
                        accept=".xlsx, .xls"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </DrawerContainer>
          </div>
        </>) : (<div className={classes.emptySty} >
          Something went wrong!
        </div>)}
      </>)}
    </div>
  );
};
export default PlanningTarget;
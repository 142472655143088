const { default: Api } = require("../../helpers/Api");

export const createTargetApiCall = async (obj) => {
    try {
        const res = await Api.post("/target/create", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateTargetApiCall = async (obj) => {
    try {
        const res = await Api.post("/target/update", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getTargetsApiCall = async (obj) => {
    try {
        const res = await Api.post("/target/get", obj);
        const data = res?.data || [];
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteTargetApiCall = async (obj) => {
    try {
        const res = await Api.post("/target/delete", obj);
        const data = res?.data || [];
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getTaskForParent = async (obj) => {
    try {
        const res = await Api.post("wbs/get/for-parent", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getObservations = async (obj) => {
    try {
        const res = await Api.post("site-observation/get/for-topParent", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getPlanningCount = async (obj) => {
    try {
        const res = await Api.post("wbs/get/planning/home-count", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOptionsForTargetFilter = async (obj) => {
    try {
        const res = await Api.post("target/get/filterOptions", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOptionsForTasksFilter = async (obj) => {
    try {
        const res = await Api.post("wbs/get/tasks/filter/options", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getOptionsForObservationsFilter = async (obj) => {
    try {
        const res = await Api.post("site-report/get/filterOptions", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllTasksUnderTarget = async (obj) => {
    try {
        const res = await Api.post("wbs/get/for-target", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getTargetDetailsById = async (obj) => {
    try {
        const res = await Api.post("/target/get-by-id", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getTaskDetailsById = async (obj) => {
    try {
        const res = await Api.post("/wbs/get-by-id", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getSubTasksByTargetId = async (obj) => {
    try {
        const res = await Api.post("/wbs/subtasks", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getBugsByTargetId = async (obj) => {
    try {
        const res = await Api.post("/bug/get-by-task", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const removeDocFromTask = async (obj) => {
    try {
        const res = await Api.post("task/remove/doc", obj);
        const data = res?.data || {};
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};
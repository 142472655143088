import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid, Skeleton, Chip, Button, Box, MenuItem
} from "@mui/material";
import { TextField } from '@material-ui/core';
import { Add } from "@material-ui/icons";
import FormBox from "../styled/generic/FormBox.jsx";
import SpaceBetween from "../styled/generic/SpaceBetween.jsx";
import dayjs from "dayjs";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import SearchField from "../styled/generic/SearchField";
import Select from "../styled/generic/Select";
import DatePicker from "../styled/generic/DatePicker.jsx";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getWalletDataByProfileId } from "../finance2o/accounts/api.call.js";
import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import emptyPage from "../../Assets/EmptyPageSVG.svg";
import OrgPicker from "../styled/generic/OrgPicker.jsx";
import ProjectPicker from "../styled/generic/ProjectPicker.jsx";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer.js";
import { getProfileParent } from "../AssetsManagement/api.call.js";
import { BiPlus } from "react-icons/bi";
import LibraryImport from "../OfferingsAndProducts/Library.Import.js";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { FaFilter } from "react-icons/fa6";
import TargetCard from "./TargetCard.jsx";
import { createTargetApiCall, deleteTargetApiCall, getOptionsForTargetFilter, getTargetsApiCall, updateTargetApiCall } from "./api.call.js";
import { useDebounce } from "react-use";
import MyPopOver from "../styled/CommonComponents/MyPopOver.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white"
  },
  header: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 20px"
  },
  bodyCont: {
    width: "100%",
    height: "calc(100% - 100px)",
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
    padding: "20px",
    alignContent: "flex-start"
  },
  emptyLibrarayCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& div": {
      width: "100%",
      marginTop: "10px",
      padding: "0px 20px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue",
    },
  },
  drawerBody: {
    width: "100%",
    padding: "20px",
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  drawerBottomBtn: {
    width: "100%",
    height: "80px",
    paddingBottom: "20px",
    padding: "0px 20px",
  },
  organizationCover: {
    position: "relative",
    width: "100%",
  },
  editOrg: {
    width: "fit-content",
    position: "absolute",
    right: "1rem",
    bottom: "3rem",
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #d2d1d1",
  },
  filterInputOp: {
    width: "450px",
    padding: "20px"
  },
  loaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const ORDER_OPTIONS = {
  "Title": "title",
  "Created Date": "createdAt",
  "Start Date": "startDate",
  "End Date": "endDate"
}

const TargetsList = ({ entityType }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { profileId } = useParams();

  const [openCreateTargetDrawer, setOpenCreateTargetDrawer] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "week"));
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [targets, setTargets] = useState([]);
  const [curPage, setCurPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(0)
  const [targetsLoading, setTargetsLoading] = useState(false);
  const [libraryGetLoader, setLibraryGetLoader] = useState(false);
  const [walletData, setWalletData] = useState();
  const [targetToUpdate, setTargetToUpdate] = useState(null);
  const [curProfile, setCurProfile] = useState(null);
  const [isDisabledOrg, setIsDisabledOrg] = useState(false);
  const [isDisabledProject, setIsDisabledProject] = useState(false);
  const [library, setLibrary] = useState(null);
  const [libraryImportModel, setLibraryImportModel] = useState(false);
  const [stateChange, setStateChange] = useState(false)
  const [searchStr, setSearchStr] = useState("")
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [filterProjects, setFilterProjects] = useState([])
  const [filterProjectsOptions, setFilterProjectsOptions] = useState([])

  useEffect(() => {
    getOptionsForTargetFilter({ profileId: profileId })
      .then((data) => {
        setFilterProjectsOptions(data?.projects || [])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  //create target api call
  const createTarget = async () => {
    try {
      await createTargetApiCall({
        title: title,
        organization: selectedOrganization?._id,
        project: selectedProject?._id,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        description: description,
        creator: user?.profile,
        buildingCodeLibrary: walletData?.defaultLibrary,
      })
        .then((data) => {
          if (data) {
            history.push(`/planning/target/${data._id}`);
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: true,
                message: "Target created successfully",
              },
            });
            curPage(1)
            setStateChange(!stateChange)
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  //get tagets
  const getTargets = async () => {
    try {
      setTargetsLoading(true);
      await getTargetsApiCall({
        profileId: profileId,
        curPage: curPage - 1,
        limit: pageLimit,
        searchString: searchStr,
        projects: filterProjects ? filterProjects.map((s) => s?._id) : [],
        orderFilter: ORDER_OPTIONS?.[orderFilter],
        orderFilterOrder: orderFilterOrder === "Ascending" ? -1 : 1
      })
        .then((data) => {
          if (data) {
            setTargets(data?.targets);
            let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
            setTotalPages(locTotalPage)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get targets",
        },
      });
    } finally {
      setTargetsLoading(false);
    }
  };

  useDebounce(() => {
    getTargets()
  }, 1000, [stateChange])

  useEffect(() => {
    setStateChange(!stateChange)
  }, [curPage]);

  useEffect(() => {
    setCurPage(1)
    setStateChange(!stateChange)
  }, [searchStr, filterProjects, orderFilter, orderFilterOrder])

  //get wallet data once only
  useEffect(() => {
    if (profileId && profileId !== null && profileId != undefined) {
      setLibraryGetLoader(true)
      getWalletDataByProfileId({ profileId: profileId })
        .then(async (data) => {
          setWalletData(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLibraryGetLoader(false)
        })
    }
  }, [profileId]);

  //get profile parent once only
  useEffect(() => {
    if (profileId) {
      getProfileParent({ profileId })
        .then((data) => {
          setCurProfile(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  //set data in target from add edit
  useEffect(() => {
    if (targetToUpdate) {
      setSelectedOrganization(targetToUpdate?.organization?._id);
      setSelectedProject(targetToUpdate?.project?._id);
      setStartDate(dayjs(targetToUpdate?.startDate));
      setEndDate(dayjs(targetToUpdate?.endDate));
      setTitle(targetToUpdate?.title);
      setDescription(targetToUpdate?.description);
      let targetLib = targetToUpdate?.buildingCodeLibrary;
      setLibrary(targetLib);
    } else {
      setSelectedOrganization("");
      setSelectedProject("");
      setStartDate(dayjs());
      setEndDate(dayjs().add(1, "week"));
      setTitle("");
      setDescription("");
    }
  }, [targetToUpdate]);

  //reset target add edit form
  useEffect(() => {
    if (!openCreateTargetDrawer) {
      setSelectedOrganization("");
      setSelectedProject("");
      setStartDate(dayjs());
      setEndDate(dayjs().add(1, "week"));
      setTitle("");
      setDescription("");
    }
  }, [openCreateTargetDrawer]);

  //clear target add edit form
  const clearForm = () => {
    setTargetToUpdate(null);
    setOpenCreateTargetDrawer(false);
  };

  //to update target
  const updateTarget = async () => {
    try {
      updateTargetApiCall({
        targetId: targetToUpdate?._id,
        updateBody: {
          title: title,
          organization: selectedOrganization?._id,
          project: selectedProject?._id,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          description: description,
          buildingCodeLibrary: library?._id,
        },
      })
        .then((data) => {
          if (data) {
            //history.push(`/planning/target/${data._id}`);
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: true,
                message: "Target updated successfully",
              },
            });
            setStateChange(!stateChange)
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      clearForm();
    }
  };

  //to delete target
  const deleteTarget = async () => {
    try {
      await deleteTargetApiCall({ targetId: targetToUpdate?._id })
        .then((data) => {
          if (data) {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: true,
                message: "Target deleted successfully",
              },
            });
            setStateChange(!stateChange)
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      clearForm();
    }
  };

  return (
    <div className={classes.mainCont} >
      {libraryGetLoader ? (<div className={classes.loaderCont} >
        <CircularProgress />
      </div>) : (<>
        {walletData?.defaultLibrary ? (
          <>
            <div className={classes.header} >
              <MyPopOver
                closeOnClick={false}
                appearContent={
                  <Button
                    startIcon={<FaFilter />}
                    variant="outlined"
                    color="primary"
                  >
                    Add Filter
                  </Button>
                }
                showContent={<div className={classes.filterInputOp} >
                  <Box flex="1" style={{ marginBottom: "15px" }} >
                    <SearchField
                      value={searchStr}
                      onChange={(e) => setSearchStr(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                      }}
                      fullWidth={true}
                      size="small"
                    />
                  </Box>
                  {entityType === "Organization" && (
                    <Box flex="1" style={{ marginBottom: "15px" }} >
                      <Autocomplete
                        options={filterProjectsOptions}
                        value={filterProjects}
                        onChange={(event, value) => {
                          setFilterProjects(value)
                        }}
                        multiple={true}
                        fullWidth
                        size="small"
                        getOptionLabel={(option) => option?.displayName}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            {...params}
                            placeholder={"Select project"}
                          />
                        )}
                      />
                    </Box>
                  )}
                  <Box flex="1" >
                    <BoxSpaceBetween>
                      <Select
                        value={orderFilter}
                        onChange={(e) => setOrderFilter(e.target.value)}
                        displayEmpty
                        size="small"
                        style={{ width: "200px" }}
                      >
                        <MenuItem value="">Filter (None)</MenuItem>
                        {Object.keys(ORDER_OPTIONS).map((option) => (
                          <MenuItem value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                      <Select
                        value={orderFilterOrder}
                        onChange={(e) => setOrderFilterOrder(e.target.value)}
                        displayEmpty
                        size="small"
                        style={{ width: "200px" }}
                      >
                        <MenuItem value="Ascending">Ascending</MenuItem>
                        <MenuItem value="Descending">Descending</MenuItem>
                      </Select>
                    </BoxSpaceBetween>
                  </Box>
                </div>}
              />
              <Button
                onClick={() => {
                  setOpenCreateTargetDrawer(true);
                  setTargetToUpdate("");
                }}
                startIcon={<Add />}
                variant="contained"
                color="primary"
                size="small"
              >
                Create Target
              </Button>
            </div>
            <div className={classes.bodyCont} >
              {targetsLoading ? (
                <Grid container spacing={2}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Skeleton variant="rounded" width="100%" height={200} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {targets?.map((target, index) => (
                    <TargetCard
                      target={target}
                      setTargetToUpdate={setTargetToUpdate}
                      setOpenCreateTargetDrawer={setOpenCreateTargetDrawer}
                    />
                  ))}
                </Grid>
              )}
              <NormalDrawer
                openDrawer={openCreateTargetDrawer}
                setOpenDrawer={setOpenCreateTargetDrawer}
                anchor={"right"}
                width={"40vw"}
                title={targetToUpdate ? "Update Target" : "Create New Target"}
                content={
                  <>
                    <div className={classes.drawerBody}>
                      <FormBox label="Title">
                        <TextField
                          fullWidth
                          placeholder="Enter"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormBox>
                      <SpaceBetween
                        left={
                          <FormBox label="Organization">
                            <div className={classes.organizationCover}>
                              <OrgPicker
                                disabled={isDisabledOrg}
                                selectedOrg={selectedOrganization}
                                setSelectedOrg={setSelectedOrganization}
                                defaultOrganizationId={
                                  targetToUpdate?._id
                                    ? targetToUpdate?.organization?._id
                                    : curProfile?.parent?.ownerProfile?.parent
                                }
                                hideLabel={true}
                                fullWidth={true}
                              />
                              {selectedOrganization && (
                                <EditIcon
                                  sx={{ fontSize: "1rem" }}
                                  className={classes.editOrg}
                                  onClick={() => setIsDisabledOrg(!isDisabledOrg)}
                                />
                              )}
                            </div>
                          </FormBox>
                        }
                        right={
                          <FormBox label="Project">
                            <div className={classes.organizationCover}>
                              <ProjectPicker
                                disabled={isDisabledProject}
                                defaultProjectId={
                                  targetToUpdate?._id
                                    ? targetToUpdate?.project?._id
                                    : curProfile?.parent?._id
                                }
                                selectedProject={selectedProject}
                                setSelectedProject={setSelectedProject}
                                hideLabel={true}
                                fullWidth={true}
                              />
                              {selectedProject && (
                                <EditIcon
                                  sx={{ fontSize: "1rem" }}
                                  className={classes.editOrg}
                                  onClick={() =>
                                    setIsDisabledProject(!isDisabledProject)
                                  }
                                />
                              )}
                            </div>
                          </FormBox>
                        }
                      />
                      <SpaceBetween
                        left={
                          <FormBox label="Start Date">
                            <DatePicker value={startDate} onChange={setStartDate} />
                          </FormBox>
                        }
                        right={
                          <FormBox label="End Date">
                            <DatePicker value={endDate} onChange={setEndDate} />
                          </FormBox>
                        }
                      />
                      <LibraryImport
                        importLibraryOpen={libraryImportModel}
                        setImportLibraryOpen={setLibraryImportModel}
                        importForIssues={true}
                        library={library}
                        setLibrary={(data) => {
                          setLibrary(data[data.length - 1].library);
                          console.log("library:", data[data.length - 1].library);
                        }}
                      />
                      <FormBox
                        label={"Select Library"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {targetToUpdate ? <>
                          {library ? (
                            <Chip
                              color="primary"
                              label={library.name}
                              icon={<LibraryBooksIcon />}
                              onDelete={() => setLibrary(null)}
                              variant="filled"
                              size="medium"
                              avatar={<LibraryBooksIcon />}
                            />
                          ) : (
                            <Button
                              variant="contained"
                              size="medium"
                              onClick={() => setLibraryImportModel((prev) => !prev)}
                              startIcon={<BiPlus />}
                            >
                              Import Library
                            </Button>
                          )}
                        </> : null}
                      </FormBox>
                      <FormBox label="Description">
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="Enter"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </FormBox>
                    </div>
                    <div className={classes.drawerBottomBtn}>
                      <DuoButtonGroup
                        primaryButtonText={targetToUpdate ? "Update" : "Create"}
                        primaryButtonListener={() => {
                          if (targetToUpdate) {
                            updateTarget();
                          } else {
                            createTarget();
                          }
                        }}
                        secondaryButtonText={targetToUpdate ? "Delete" : "Clear"}
                        secondaryButtonListener={() => {
                          targetToUpdate ? deleteTarget() : clearForm();
                        }}
                      />
                    </div>
                  </>
                }
              />
            </div>
            <div className={classes.paginationCont} >
              <Pagination
                count={totalPages}
                page={curPage}
                color="primary"
                siblingCount={0}
                onChange={(event, value) => { setCurPage(value) }}
              />
            </div>
          </>
        ) : (
          <div className={classes.emptyLibrarayCont}>
            <img src={emptyPage} />
            <div>
              <p>
                No Library exists in the current directory or any library still
                didn't selected as default library.
                <br />
                Click{" "}
                <a
                  onClick={() => {
                    history.push(`/offerings-categories/management/${profileId}`);
                  }}
                >
                  here
                </a>{" "}
                to create and manage your library(s), category(s), product(s) and
                service(s)
              </p>
            </div>
          </div>
        )}
      </>)}
    </div>
  );
};

export default TargetsList;
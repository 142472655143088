import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { firebaseLoginHelper } from "./components/auth/auth.utils";
import { useFeedData } from "./components/explore/feed.hooks";
import { useGetTeams } from "./components/team/team.hooks";
import { useGetUserNotification } from "./components/activity/activity.hooks";
import BasicPrivateRoute from "./components/PrivateRoute/BasicPrivateRoute";
import AuthPage from "./components/auth/Auth";
import firebase from "firebase";
import GuestRoute from "./components/GuestRoute/GuestRoute";
import ProfileChat from "./components/profileChat/index";
import Projects from "./components/projects/projects";
import DashboardRoutes from "./routes/dashboard.routes";
// import ExploreRoutes from "./routes/explore.routes";
// import TeamRoutes from "./routes/team.routes";
// import BlogRoutes from "./routes/blog.routes";
// import AccountingRoutes from "./routes/accounting.routes";
// import Account from "./components/account/account";
import InvitationLink from "./components/team/invitation.link.handler";
// import Subscription from "./components/finance2o/checkout/subscription/index";
// import CheckoutBackdrop from "./components/finance2o/checkout/checkoutBackdrop";
import theme from "./utils/theme";
import Api from "./helpers/Api";
import Backdrop from "./components/styled/backdrop.style";
import useProfile from "./components/profile/useProfile";
import Landing from "./components/landing2/index";
import useSocketHook from "./helpers/socket/useSocketHook";
// import Organizations from "./components/organization/organizations";
import Layout from "./components/layout";
// import RentalRelationView from "./components/ProjectAnalysis/rental.relation.view";
import NotificationAlert from "./components/activity/NotificationAlert";
// import PayBackTx from "./components/finance2o/commonComponent/payBackTx";
// import PaymentSuccess from "./components/styled/CommonComponents/PaymentSuccess.js";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import AuthBox from "./components/auth/AuthBox";
import TermsAndConditions from "./components/landing2/termsAndConditions/TermAndConditions";
import Privacy from "./components/landing2/termsAndConditions/Privacy";
import CommunityRules from "./components/landing2/termsAndConditions/CommunityRules";
import ApiAlert from "./components/styled/alert/api.alert";
// import DwollaPaymentStatusPage from "./components/styled/CommonComponents/DwollaPaymentStatusPage";
// import PortfolioHome from "./components/Portfolio/index";
// import LandingInvestmentAnalysis from "./components/landing2/investmentAnalysis/index";
// import SharePageRouter from "./components/PrivateRoute/SharePageRoute";
// import GuestReportView from "./components/ProjectAnalysis/GuestReportView";
// import GuestLayoutHeader from "./components/Navbar/GuestLayout.Header";
// import OrgView from "./components/organization/OrgView";
// import ReportCompare from "./components/ProjectAnalysis/Report.Compare.js/ReportCompare";
// import ProjectHome from "./components/team/Project.Home";
import ScrollToTop from "./components/styled/CommonComponents/ScrollTop";
import Error404 from "./components/ErrorPages/Error404";
import Error403 from "./components/ErrorPages/Error403";
// import OrgHome from "./components/team/Org.Home";
// import Marketplace from "./components/marketplace";
// import ProjectViewHome from "./components/team/Project.View.Home";
import "./components/styled/scroll.css";
// import ProjectEstimation from "./components/team/Project.Estimation";
// import ProjectLibrary from "./components/team/Project.Library";
// import SiteManagement from "./components/sitemanagement/SiteManagement";
// import WorkSchedule from "./components/team/WorkSchedule/WorkSchedule";
// import Procurement from "./components/team/procurements/Procurement";
// import OpsOrder from "./components/team/procurements/OpsOrder";
import ProjectLibraryView from "./components/team/Project.Library.View";
// import Inventory from "./components/team/inventory/Inventory";
// import GRN from "./components/team/procurements/GRN";
// import FinanceRelationView from "./components/FinanceRelationView";
import MarketplaceExplore from "./components/marketplace/MarketplaceExplore.jsx";
// import SiteReport from "./components/sitereport/SiteReport.jsx";
// import EditSiteReport from "./components/sitereport/EditSiteReport.jsx";
// import ViewSiteReport from "./components/sitereport/ViewSiteReport.jsx";
// import HR from "./components/hr/HR.jsx";
// import HRSettings from "./components/hr/settings/HRSettings.jsx";
// import MyWork from "./components/mywork/MyWork.jsx";
// import EditEmployee from "./components/hr/employees/EditEmployee.jsx";
import AccessControlStoreHook from "./components/AccessControl/AccessControlStoreHook.js";
// import BulkAddEmployees from "./components/hr/employees/BulkAddEmployees.jsx";
// import PayrollChecks from "./components/hr/payroll/payrollcheck/PayrollChecks.jsx";
// import PaySlipTemplateEdit from "./components/hr/settings/finance/payslip/PaySlipTemplateEdit.jsx";
// import EmployeeAbout from "./components/hr/employees/about/EmployeeAbout.jsx";
// import EndEmployeeContract from "./components/hr/employees/endcontract/EndEmployeeContract.jsx";
import { getAuthCookieData, setAuthCookieData } from "./helpers/cookie.helper";
import MyNotifications from "./components/Notifications/MyNotifications.jsx";
import OfferingsAndProductsManagement from "./components/OfferingsAndProducts/OfferingsAndProductsManagement.js";

import PlanningHome from "./components/planning/PlanningHome.jsx";
import PlanningTarget from "./components/planning/target/PlanningTarget.jsx";
import TaskDetails from "./components/planning/tasks/TaskDetails.jsx";
import EditObservation from "./components/sitereport/EditObservation.jsx"
import PreviewObservation from "./components/sitereport/PreviewObservation.jsx"

const App = (props) => {
  const Auth = firebase.auth();
  const dispatch = useDispatch();
  const themeMI = useTheme();
  const state = useSelector((state) => state);
  const { auth, appGlobal } = state;
  const { user, isFromSignup } = auth;
  const { backdropVisible } = appGlobal;
  localStorage.setItem("activeUserId", user?._id);
  localStorage.setItem("activeUserProfileId", user?.profile);
  const profileId = user?.profile;
  const isMobile = useMediaQuery(themeMI?.breakpoints.down("xs"));
  const history = useHistory();
  const location = useLocation();
  const pathname = location["pathname"];
  const oldDisplayName = user?.displayName;

  const { getParticipantAccess, checkAccess } = AccessControlStoreHook();
  let currentUrl = window.location.href;

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === '' || currentPath === '/') {
      window.location.href = 'https://reallist.ai/';
    }
  }, [location]);

  
  useEffect(() => {
    const initAuth = async () => {
      const maxRetries = 3;
      let retries = 0;
      
      const attemptAuth = async () => {
        try {
          const { user, token, entireState } = await getAuthCookieData(dispatch);
          if (user && user?._id && entireState) {
            console.log("User and entire state retrieved successfully");
            console.log('user in app.js ',user)
            dispatch({
              type: "SET_AUTH_USER",
              user,
            });
            return true;
          } else {
            console.warn("No user or entire state retrieved");
            return false;
          }
        } catch (error) {
          console.error("Error initializing auth:", error);
          return false;
        }
      };

      while (retries < maxRetries) {
        const success = await attemptAuth();
        if (success) break;
        retries++;
        if (retries < maxRetries) {
          console.log(`Retrying auth initialization (${retries}/${maxRetries})`);
          await new Promise(resolve => setTimeout(resolve, 500)); // Wait 1 second before retrying
        }
      }

      if (retries === maxRetries) {
        console.error("Auth initialization failed after max retries");
        history.goBack();
      }
    };

    initAuth();
  }, []);


  // useEffect(() => {
  //   getParticipantAccess();
  //   let cookieData = getAuthCookieData(dispatch);
  //   console.log(cookieData, " is stored in cookie");
  // }, []);

  // useEffect(() => {
  //   if (user?._id) {
  //     if (
  //       user?.displayName &&
  //       user.displayName.length > 2 &&
  //       user?.wallet &&
  //       user?.email &&
  //       user?.workspaceConfig
  //     ) {
  //       console.log("all good with the profile");
  //     } else {
  //       if (
  //         !currentUrl.includes("/pre-login") &&
  //         !currentUrl.includes("/account")
  //       ) {
  //         let path = "/pre-login/";
  //         history.push(path)
  //       }
  //     }
  //   }
  // }, [user, currentUrl, pathname]);

  // useSocketHook(profileId);
  // useGetUserNotification();
  // useFeedData();
  // useProfile();
  useGetTeams();

  // useEffect(() => {
  //   dispatch({
  //     type: "AddChat",
  //     payload: {
  //       convIds: [],
  //       convDict: {},
  //       msgMap: {},
  //       msgDict: {},
  //     },
  //   });
  // }, []);

  // async function testAuth() {
  //   const fireBaseUser = await firebase.auth();
  //   if (user && user.error && fireBaseUser && fireBaseUser.currentUser)
  //     fireBaseUser.currentUser
  //       .getIdToken(/* forceRefresh */ true)
  //       .then(function (idToken) {
  //         // Send token to your backend via HTTPS
  //         localStorage.setItem("token", idToken);
  //       })
  //       .catch(function (error) {
  //         // Handle error
  //         console.error("Firebase : ", error);
  //       });
  //   firebaseLoginHelper(fireBaseUser?.currentUser, dispatch);
  // }

  // useEffect(() => {
  //   dispatch({ type: "FileUploadReset" });
  //   dispatch({
  //     type: "AddAuth",
  //     payload: { signinupLoader: false },
  //   });
  //   // if (Auth.currentUser) {
  //   //   if (!user?._id) {
  //   //     const currentUser = Auth.currentUser;
  //   //     firebaseLoginHelper(currentUser, dispatch);
  //   //   }
  //   // }
  //   // testAuth();
  // }, [Auth.currentUser]);

  //to store user data in localStorage
  useEffect(async () => {
    if (user?._id) {
      localStorage.setItem("activeUserId", user?._id);
      localStorage.setItem("activeUserProfileId", user?.profile);
      // const token = localStorage.getItem("token");
      // await setAuthCookieData({ user, token });
    }
  }, [user?._id]);

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "dd8e5c00-523b-4735-b07d-2cd4af497f58"
  //   });
  // }, []);

  // useEffect(() => {
  //   if (user && user._id) {
  //     Api.post("invite/getUserInvites", { user: [user.profile] }).then(
  //       (res) => {
  //         const invitations = res.invitations;
  //         console.log(res);

  //         dispatch({
  //           type: "AddTeam",
  //           payload: {
  //             invitations,
  //           },
  //         });
  //       }
  //     );
  //   }
  // }, [user]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={props.history}>
          <ScrollToTop>
            <CssBaseline />
            <Switch>
              {/* {BlogRoutes()} */}
              {/* {ExploreRoutes()} */}
              {DashboardRoutes()}
              {/* {TeamRoutes()} */}
              {/* {AccountingRoutes()} */}
              {/* <BasicPrivateRoute
                exact
                path="/offerings-categories/management/:profileId"
                noAppbar={true}
                useBothSide={true}
                component={OfferingsAndProductsManagement}
              />,
              <BasicPrivateRoute
                exact
                path="/"
                component={Marketplace}
                useBothSide={true}
              />
              <BasicPrivateRoute
                exact
                path="/marketplace/search"
                component={MarketplaceExplore}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/projects"
                component={Projects}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/project/sitemanagement/:projectId"
                noAppbar={true}
                component={SiteManagement}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/project/:teamId"
                noAppbar={false}
                component={ProjectHome}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/contact/:id"
                noAppbar={true}
                component={FinanceRelationView}
                useBothSide={true}
              /> */}

              {/* <BasicPrivateRoute
                exact
                path="/project/:teamId/:profileId"
                noAppbar={true}
                component={ProjectEstimation}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/organization/library/configure/:profileId/:orgId/"
                noAppbar={true}
                component={ProjectLibrary}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/project/:teamId/:profileId/library/:libraryId"
                noAppbar={true}
                component={ProjectLibraryView}
                useBothSide={true}
              /> */}

              {/* <BasicPrivateRoute
                exact
                path="/project/view/:teamId"
                noAppbar={true}
                component={ProjectViewHome}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/portfolio/:portfolioId"
                noAppbar={true}
                component={PortfolioHome}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/account"
                component={Account}
                useBothSide={true}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/organizations"
                component={Organizations}
              />
              <BasicPrivateRoute
                exact
                path="/organization/:teamId"
                noAppbar={true}
                useBothSide={true}
                component={OrgHome}
              /> */}
              <BasicPrivateRoute
                exact
                path="/notifications"
                noAppbar={true}
                useBothSide
                component={MyNotifications}
              />
              <BasicPrivateRoute
                exact
                path="/offerings-categories/management/:profileId"
                noAppbar={true}
                useBothSide={true}
                component={OfferingsAndProductsManagement}
              />,

              {/* <BasicPrivateRoute
                exact
                path="/organization/workschedule/:teamId"
                noAppbar={false}
                useBothSide={true}
                component={WorkSchedule}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/site-report/:profileId"
                noAppbar={true}
                useBothSide
                component={SiteReport}
              />
              <BasicPrivateRoute
                exact
                path="/site-report/edit/:reportId"
                noAppbar={true}
                useBothSide
                component={EditSiteReport}
              />
              <BasicPrivateRoute
                exact
                path="/site-report/view/:reportId"
                noAppbar={true}
                useBothSide
                component={ViewSiteReport}
              />

              <BasicPrivateRoute
                exact
                path="/hr/:organizationId"
                noAppbar={true}
                useBothSide
                component={HR}
              />

              <BasicPrivateRoute
                exact
                path="/hr/settings/:organizationId"
                noAppbar={true}
                useBothSide
                component={HRSettings}
              />

              <BasicPrivateRoute
                exact
                path="/hr/employees/employee/about/:employeeId"
                noAppbar={true}
                useBothSide
                component={EmployeeAbout}
              />

              <BasicPrivateRoute
                exact
                path="/hr/employees/employee/:employeeId/end-contract"
                noAppbar={true}
                useBothSide
                component={EndEmployeeContract}
              />

              <BasicPrivateRoute
                exact
                path="/payslip/template/:templateId"
                noAppbar={true}
                useBothSide
                component={PaySlipTemplateEdit}
              />

              <BasicPrivateRoute
                exact
                path="/payroll/:payrollRunId"
                noAppbar={true}
                useBothSide
                component={PayrollChecks}
              />

              <BasicPrivateRoute
                exact
                path="/hr/employees/employee/:organizationId"
                noAppbar={true}
                useBothSide
                component={EditEmployee}
              />

              <BasicPrivateRoute
                exact
                path="/hr/employees/bulk/:organizationId"
                noAppbar={true}
                useBothSide
                component={BulkAddEmployees}
              />

              <BasicPrivateRoute
                exact
                path="/my-work/:profileId"
                noAppbar={true}
                useBothSide
                component={MyWork}
              />

              <BasicPrivateRoute
                exact
                path="/inventory"
                noAppbar={true}
                useBothSide
                component={Inventory}
              />
              <BasicPrivateRoute
                exact
                path="/procurements"
                noAppbar={true}
                useBothSide
                component={Procurement}
              />
              <BasicPrivateRoute
                exact
                path="/procurements/ops-order/:opsOrderId/:billListId"
                noAppbar={true}
                useBothSide
                component={OpsOrder}
              />
              <BasicPrivateRoute
                exact
                path="/procurements/grn/:opsId/"
                noAppbar={true}
                useBothSide
                component={GRN}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/organization/:teamId/orgView"
                noAppbar={true}
                useBothSide={true}
                component={OrgView}
              />
              <BasicPrivateRoute
                exact
                path="/profile/chat/:profileId/:convId"
                hideDrawer={false}
                noAppbar={true}
                component={ProfileChat}
              /> */}
              {/* <BasicPrivateRoute
                exact
                path="/messages"
                noAppbar={true}
                hideDrawer={isMobile ? true : false}
                component={ProfileChat}
              /> */}
              {/* <Route exact path="/payment/:txId">
                <PayBackTx />
              </Route> */}
              {/* <BasicPrivateRoute
                exact
                path="/messages/:conversationId"
                noAppbar={true}
                component={ProfileChat}
              /> */}
              {/* <SharePageRouter
                exact
                path="/shared/report/analysis/:reportId/view"
                component={GuestReportView}
                HeaderProp={GuestLayoutHeader}
                headerHeight={"50px"}
              />
              <SharePageRouter
                exact
                path="/investment/analysis/compare/:compareId/:publicParam/share"
                component={ReportCompare}
                HeaderProp={GuestLayoutHeader}
                headerHeight={"50px"}
              />
              <Route
                exact
                path="/:paymentpurpose/paid/success/:relatedinfo/:txId"
              >
                <PaymentSuccess />
              </Route>
              <Route exact path="/invoice/payment/status/:dwollaTxId/:txId">
                <DwollaPaymentStatusPage />
              </Route>
              <BasicPrivateRoute
                exact
                path="/tenant/:tenantRelationId"
                component={RentalRelationView}
              /> */}

              <BasicPrivateRoute
                  exact
                  path="/planning/:profileId"
                  noAppbar={true}
                  useBothSide
                  component={PlanningHome}
                />,



                <BasicPrivateRoute
                  exact
                  path="/planning/target/:targetId"
                  noAppbar={true}
                  useBothSide
                  component={PlanningTarget}
                />,

                <BasicPrivateRoute
                  exact
                  path="/planning/task/:taskId"
                  noAppbar={true}
                  useBothSide
                  component={TaskDetails}
                />,

                <BasicPrivateRoute
                      exact
                      path="/observation/edit/:observationId"
                      noAppbar={true}
                      useBothSide
                      component={EditObservation}
                    />,

                    <BasicPrivateRoute
                      exact
                      path="/observation/preview/:observationId"
                      noAppbar={true}
                      useBothSide
                      component={PreviewObservation}
                    />,

              <GuestRoute exact path="/login" component={AuthPage} />
              <GuestRoute exact path="/signup" component={AuthPage} />
              <Route exact path="/layout">
                <Layout>
                  <h1>TITLE OF THE PAGE</h1>
                  This is Layout for logged in pages
                </Layout>
              </Route>
              {/* <Route exact path="/user/subscription">
                <Subscription />
              </Route> */}
              <Route exact path="/invitation/:invitationId">
                <InvitationLink />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/reset-password">
                <ResetPassword />
              </Route>
              <Route exact path="/authenticate">
                <AuthBox />
              </Route>
              <Route exact path="/terms">
                <TermsAndConditions />
              </Route>
              {/* <Route exact path="/investment/analysis/new">
                <LandingInvestmentAnalysis />
              </Route> */}
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route exact path="/rules">
                <CommunityRules />
              </Route>
              <GuestRoute exact path="/about" component={Landing} />
              <BasicPrivateRoute path="/403" component={Error403} />
              <BasicPrivateRoute path="*" component={Error404} />
            </Switch>
            <Backdrop open={backdropVisible} />
            {/* <CheckoutBackdrop /> */}
          </ScrollToTop>
        </ConnectedRouter>
        <NotificationAlert />
        <ApiAlert />
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;

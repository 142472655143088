import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Typography,
  Tooltip,
  tooltipClasses,
  IconButton,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getProgressColor } from "../../../../helpers/colorHelper";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import AddTaskDialog from "./AddTaskDialog";
import Api from "../../../../helpers/Api";
import { useHistory } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const TaskCardDetail = ({ task, onUpdateClick, onTaskUpdate, onTaskDelete }) => {
  const formattedDate = new Date(task?.createdAt).toLocaleDateString();
  const history = useHistory();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setShowEditTaskDialog(true);
    handleOptionsClose();
  };

  const handleDelete = async () => {
    try {
      await Api.post("/wbs/delete", { taskIds: [task._id] });
      onTaskDelete(task);
      history.goBack();
    } catch (error) {
      console.error("Error deleting task:", error);
      // You might want to show an error message to the user here
    }
    handleOptionsClose();
  };

  return (
    <Box sx={{ backgroundColor: 'white', p: 3, borderRadius: '12px' }}>
      <BoxSpaceBetween>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar
            alt={task?.creator?.parent?.displayName}
            src={task?.creator?.parent?.displayPicture?.url}
            sx={{ width: 48, height: 48 }} 
          />
          <Box>
            <Typography variant="h6">{task?.creator?.parent?.displayName}</Typography>
            <Typography variant="body2" color="text.secondary">{formattedDate}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body2">Start: {new Date(task?.startDate).toLocaleDateString()}</Typography>
          <Typography variant="body2">End: {new Date(task?.endDate).toLocaleDateString()}</Typography>
        </Box>
        <IconButton onClick={handleOptionsClick}>
          <MoreVertIcon />
        </IconButton>
      </BoxSpaceBetween>

      <Typography variant="h4" sx={{ my: 3 }}>{task?.taskName}</Typography>
      
      {task?.description && (
        <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
          {task.description}
        </Typography>
      )}

      {task?.target?.project?.name && (
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Project: {task?.project?.displayName}
        </Typography>
      )}

      {task?.target?.blocks && task.target.blocks.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Blocks:</Typography>
          <HorizBox>
            {task?.projectBlocks.map((block) => (
              <Box
                key={block?._id}
                sx={{
                  backgroundColor: "#f0f0f0",
                  color: "#333",
                  padding: "4px 8px",
                  borderRadius: "10px",
                  fontSize: '0.875rem',
                  marginRight: '8px',
                }}
              >
                {block?.name}
              </Box>
            ))}
          </HorizBox>
        </Box>
      )}

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Assigned to:</Typography>
        <AvatarGroup max={10}>
          {task?.assigned?.map(({ parent }) => (
            <BootstrapTooltip key={parent?.id} title={parent?.displayName} placement="top">
              <Avatar
                alt={parent?.displayName}
                src={parent?.displayPicture?.thumbUrl || parent?.displayPicture?.url}
                sx={{ width: 30, height: 30 }} 
              />
            </BootstrapTooltip>
          ))}
        </AvatarGroup>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Labels:</Typography>
        <HorizBox>
          {task?.labels?.map((item) => (
            <Box
              key={item.id}
              sx={{
                backgroundColor: item?.colorCode + "33",
                color: item?.colorCode,
                padding: "4px 8px",
                borderRadius: "10px",
                fontSize: '0.875rem',
              }}
            >
              {item?.name}
            </Box>
          ))}
        </HorizBox>
      </Box>

      <Box
        sx={{
          backgroundColor: "#d1d5db",
          p: 2,
          borderRadius: "10px",
          position: "relative",
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: `${task?.progress || 0}%`,
            backgroundColor: getProgressColor(task?.progress)?.backgroundColor,
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            borderRadius: "10px 0 0 10px",
          }}
        />
        <BoxSpaceBetween sx={{ width: "100%", zIndex: 1 }}>
          <HorizBox>
            <Typography
              variant="h6"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              {task?.progress || 0}%
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              Progress
            </Typography>
          </HorizBox>
          <Button
            variant="contained"
            onClick={onUpdateClick}
            sx={{
              backgroundColor: "white",
              color: getProgressColor(task?.progress)?.color,
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Update
          </Button>
        </BoxSpaceBetween>
      </Box>

      {/* Vert Options Menu */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            startIcon={<EditOutlined />}
            onClick={handleEdit}
            fullWidth
            sx={{ justifyContent: 'flex-start', mb: 1 }}
          >
            Edit
          </Button>
          <Button
            startIcon={<DeleteOutline />}
            onClick={handleDelete}
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            Delete
          </Button>
        </Box>
      </Popover>

      {/* Edit Task Dialog */}
      <AddTaskDialog
        showAddTaskDialog={showEditTaskDialog}
        setShowAddTaskDialog={setShowEditTaskDialog}
        targetId={task?.target?._id}
        onTaskUpdate={(updatedTask) => {
          onTaskUpdate(updatedTask);
          setShowEditTaskDialog(false);
        }}
        profileId={task?.target?.organization?.profile}
        library={task?.target?.buildingCodeLibrary}
        project={task?.target?.project}
        organization={task?.target?.organization}
        taskToUpdate={task}
      />
    </Box>
  );
};

export default TaskCardDetail;